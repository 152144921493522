import * as React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';

import '../scss/index.scss';

import profileAlex from '../images/qrt-ak.png';
import profileChris from '../images/qrt-ck.png';

// Style
const gradient = {
  position: 'absolute',
  width: '0',
  height: '0'
};
// Data
const alex = {
  name: 'Alexander Kuhrt',
  title: 'Software Developer',
  linkedin: 'https://www.linkedin.com/in/alex-kuhrt',
  github: 'https://github.com/spezifanta',
  twitter: 'https://twitter.com/#/alexkuhrt'
};

const chris = {
  name: 'Christopher Kuhrt',
  title: 'Product Designer',
  linkedin: 'http://linkedin.chriskuhrt.de',
  twitter: 'http://twitter.chriskuhrt.de'
};

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>Qrt.de - Qrt Brothers</title>
        <meta name="description" content="Qrt brothers. Browsing the internet since the 90s. We build stuff."></meta>
      </Helmet>
      <header>
        <svg
          class="qrt-logo"
          viewBox="0 0 115 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M74.5201 19.8257C75.7822 17.9741 76.4153 15.6617 76.4153 12.8925C76.4153 8.96101 75.181 5.9553 72.7125 3.87535C70.244 1.7954 66.7125 0.753418 62.1179 0.753418H44.0342V38.8979H53.737V24.9714H59.6534L67.7477 38.9019H78.6568L69.0138 23.9335C71.4185 23.0438 73.258 21.6772 74.5201 19.8257ZM65.0482 16.7638C64.0887 17.5333 62.5598 17.922 60.4536 17.922H53.733V8.2677H60.4536C62.5598 8.2677 64.0887 8.65243 65.0482 9.42189C66.0038 10.1914 66.4816 11.4057 66.4816 13.0648C66.4816 14.76 66.0038 15.9944 65.0482 16.7638Z"
              fill="white"
            />
            <path
              d="M80.377 0.753418V8.44002H92.7791V38.8979H102.653V8.44002H115V0.753418H80.377Z"
              fill="white"
            />
            <path
              d="M5.67287 5.6785C-1.89096 13.2422 -1.89096 25.5111 5.67287 33.0825C12.2404 39.6499 22.3562 40.5082 29.8587 35.665L31.986 37.7923C32.6818 38.488 33.8102 38.488 34.5059 37.7923L37.5895 34.7087C38.2847 34.0136 38.2847 32.887 37.5895 32.1919L35.5067 30.1091C40.5262 22.5837 39.7216 12.3149 33.0774 5.6785C25.5059 -1.89284 13.2367 -1.89284 5.67287 5.6785ZM29.2763 23.8788L25.4783 20.081C24.7826 19.3853 23.6542 19.3853 22.9585 20.081L19.8748 23.1646C19.1797 23.8597 19.1797 24.9862 19.8748 25.6813L23.6053 29.4117C19.6433 31.0747 15.2062 29.9942 11.9799 26.7756C7.73434 22.5301 7.4278 15.9243 11.6733 11.6789C15.9189 7.4334 22.5248 7.73993 26.7703 11.9854C30.058 15.2806 31.0925 19.8633 29.2763 23.8788Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="115" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </header>
      <div class="container">
        <div class="wrapper">
          <div class="intro">
            <h1 class="text title hero">We build stuff</h1>
            <p class="text body">
              Creating and working with software since the 90s. 
            </p>
          </div>
          <div class="content">
            <div class="profile">
              <img
                width="80"
                height="80"
                src={profileAlex}
                alt="Alexander Kuhrt"
              ></img>
              <div class="profile_data">
                <h2 class="text title medium">{alex.name}</h2>
                <h3 class="text title sub">{alex.title}</h3>
                <ul>
                  <li>
                    <Link to={alex.linkedin} class="text link main">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.9999 18.2814H16.5625V12.7814C16.5625 12.052 16.4212 11.4787 16.1399 11.0627C15.8599 10.6454 15.4265 10.4374 14.8439 10.4374C14.4065 10.4374 14.0212 10.5734 13.6879 10.844C13.4172 11.052 13.2079 11.3334 13.0625 11.688C12.9999 11.896 12.9692 12.1774 12.9692 12.532V18.2814H9.53054C9.55187 14.3854 9.56254 11.5 9.56254 9.62535L9.53054 7.96935H12.9692V9.46935H12.9372C13.2505 8.96935 13.5932 8.59335 13.9692 8.34402C14.5305 7.92669 15.2292 7.71869 16.0625 7.71869C17.2492 7.71869 18.1879 8.10402 18.8746 8.87469C19.6252 9.68802 19.9999 10.8547 19.9999 12.3747V18.2814ZM4.18789 18.2815H7.62522V7.96946H4.18789V18.2815ZM4.516 6.04667C4.172 5.70267 4 5.276 4 4.76533C4 4.25467 4.17733 3.83333 4.53067 3.5C4.88533 3.16667 5.35467 3 5.93733 3C6.52133 3 6.984 3.16667 7.328 3.5C7.672 3.83333 7.84933 4.25467 7.86 4.76533C7.86933 5.276 7.69333 5.70267 7.328 6.04667C6.964 6.39067 6.48933 6.56267 5.90667 6.56267C5.32267 6.56267 4.86 6.39067 4.516 6.04667V6.04667Z"
                        />
                      </svg>
                      linkedin
                    </Link>
                  </li>
                  <li>
                    <Link to={alex.github} class="text link main">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.9994 2.15625C6.43445 2.15625 1.92188 6.66821 1.92188 12.235C1.92188 16.6869 4.80921 20.4639 8.81447 21.7971C9.31869 21.8893 9.50243 21.5781 9.50243 21.3109C9.50243 21.0721 9.49377 20.4379 9.48882 19.5972C6.68563 20.2059 6.09418 18.246 6.09418 18.246C5.63575 17.0817 4.97501 16.7723 4.97501 16.7723C4.06 16.1468 5.0443 16.1592 5.0443 16.1592C6.05582 16.231 6.58788 17.198 6.58788 17.198C7.48681 18.7378 8.94625 18.293 9.52099 18.0356C9.61255 17.3842 9.8724 16.94 10.1607 16.6882C7.92297 16.4339 5.57017 15.569 5.57017 11.7073C5.57017 10.6073 5.96302 9.70773 6.60768 9.00307C6.50374 8.74817 6.1579 7.72366 6.70605 6.33598C6.70605 6.33598 7.55239 6.065 9.47768 7.36916C10.2813 7.1452 11.1438 7.03384 12.0006 7.02951C12.8562 7.03384 13.7187 7.1452 14.5236 7.36916C16.4476 6.065 17.2921 6.33598 17.2921 6.33598C17.8421 7.72366 17.4963 8.74817 17.3923 9.00307C18.0382 9.70773 18.4286 10.6073 18.4286 11.7073C18.4286 15.5789 16.0721 16.4308 13.8269 16.6801C14.1889 16.9913 14.5112 17.6063 14.5112 18.5467C14.5112 19.8935 14.4988 20.9805 14.4988 21.3109C14.4988 21.5806 14.6801 21.8943 15.1917 21.7959C19.1927 20.4602 22.0781 16.6857 22.0781 12.235C22.0781 6.66821 17.5655 2.15625 11.9994 2.15625Z"
                        />
                      </svg>
                      github
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="profile">
              <img
                width="80"
                height="80"
                src={profileChris}
                alt="Christopher Kuhrt"
              ></img>
              <div class="profile_data">
                <h2 class="text title medium">{chris.name}</h2>
                <h3 class="text title sub">{chris.title}</h3>
                <ul>
                  <li>
                    <Link to={chris.linkedin} class="text link main">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.9999 18.2814H16.5625V12.7814C16.5625 12.052 16.4212 11.4787 16.1399 11.0627C15.8599 10.6454 15.4265 10.4374 14.8439 10.4374C14.4065 10.4374 14.0212 10.5734 13.6879 10.844C13.4172 11.052 13.2079 11.3334 13.0625 11.688C12.9999 11.896 12.9692 12.1774 12.9692 12.532V18.2814H9.53054C9.55187 14.3854 9.56254 11.5 9.56254 9.62535L9.53054 7.96935H12.9692V9.46935H12.9372C13.2505 8.96935 13.5932 8.59335 13.9692 8.34402C14.5305 7.92669 15.2292 7.71869 16.0625 7.71869C17.2492 7.71869 18.1879 8.10402 18.8746 8.87469C19.6252 9.68802 19.9999 10.8547 19.9999 12.3747V18.2814ZM4.18789 18.2815H7.62522V7.96946H4.18789V18.2815ZM4.516 6.04667C4.172 5.70267 4 5.276 4 4.76533C4 4.25467 4.17733 3.83333 4.53067 3.5C4.88533 3.16667 5.35467 3 5.93733 3C6.52133 3 6.984 3.16667 7.328 3.5C7.672 3.83333 7.84933 4.25467 7.86 4.76533C7.86933 5.276 7.69333 5.70267 7.328 6.04667C6.964 6.39067 6.48933 6.56267 5.90667 6.56267C5.32267 6.56267 4.86 6.39067 4.516 6.04667V6.04667Z"
                        />
                      </svg>
                      linkedin
                    </Link>
                  </li>
                  <li>
                    <Link to={chris.twitter} class="text link main">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.18 8.28043C20.18 10.2546 19.7129 12.1603 18.777 13.9976C17.8138 15.9437 16.4638 17.4932 14.7255 18.6443C12.8281 19.9341 10.6501 20.5781 8.18974 20.5781C5.8372 20.5781 3.68484 19.9341 1.73438 18.6443C2.02866 18.6724 2.36229 18.6864 2.73698 18.6864C4.68745 18.6864 6.43945 18.0688 7.98956 16.8351C7.05368 16.807 6.23243 16.5193 5.5241 15.9718C4.81577 15.4225 4.32645 14.71 4.05954 13.8326C4.35382 13.887 4.60704 13.9151 4.82091 13.9151C5.1956 13.9151 5.5703 13.8607 5.94499 13.7502C4.98173 13.5589 4.1793 13.0658 3.5377 12.2691C2.8961 11.4742 2.57616 10.5564 2.57616 9.51406V9.4737C3.16472 9.82993 3.79263 10.0212 4.4599 10.0493C3.89871 9.6369 3.44531 9.11748 3.09799 8.48574C2.74896 7.85577 2.57616 7.16262 2.57616 6.40981C2.57616 5.65524 2.77634 4.93577 3.1767 4.24964C4.22037 5.59382 5.49673 6.6625 7.00748 7.45743C8.51824 8.25236 10.1282 8.69106 11.8392 8.77353C11.7861 8.41731 11.7588 8.08916 11.7588 7.78733C11.7588 6.9924 11.947 6.26591 12.3199 5.60786C12.6946 4.94981 13.2028 4.42863 13.8444 4.04433C14.486 3.66002 15.1806 3.46875 15.93 3.46875C16.5443 3.46875 17.1123 3.59159 17.6341 3.83901C18.1542 4.08469 18.6162 4.41459 19.0166 4.82522C19.9798 4.63394 20.8746 4.29175 21.7044 3.7969C21.3828 4.81118 20.7686 5.60786 19.86 6.18344C20.6608 6.07288 21.4632 5.8395 22.2656 5.48327C21.6771 6.36067 20.9687 7.11524 20.1406 7.74522L20.18 8.28043Z"
                        />
                      </svg>
                      twitter
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SVG - Gradient - Start */}
      <svg style={gradient} aria-hidden="true" focusable="false">
        <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="link_gradient">
          <stop stop-color="#00AFFF" offset="0" />
          <stop stop-color="#4264FB" offset="100%" />
        </linearGradient>
      </svg>
      {/* SVG - Gradient - End */}
    </main>
  );
};

export default IndexPage;
